.nav-link:focus{
font-weight: 600;
color:"black"
}
.table tr{
    border-bottom-style: hidden !important;
    border-top-style: hidden !important;
}
.table td{
    margin-top: 20px !important
}
@media screen and (min-width:320px) and (max-width:480px) {
    .card{
        margin-left: 30px !important;
        padding: 0px !important;
        width: 18rem !important;
        margin-bottom: 10px !important;
        margin-top: 10px !important;
    }
}