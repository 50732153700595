.logo{
     display: block;
     width: 200px;
}
.logo img{
     display: block;
     width: 100%;
    
}

@media (max-width:1200px) {
    .logo{
        max-width: 150px !important;
    }
}
@media (max-width:768px) {
    .logo{
        max-width: 100px !important;
    }
}